import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Pane } from "evergreen-ui"
import {
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

const Container = styled.div`
  display: flex;
  flex: 1;
  height: auto;
  width: 100%;
  background: ${props => props.backgroundColor ? props.backgroundColor : "#FFFFFF"};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: ${props => props.marginTop ? props.marginTop + "px" : "10px"};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom + "px" : 0};
  margin-left: ${props => props.marginLeft ? props.marginLeft + "px" : 0};
  margin-right: ${props => props.marginRight ? props.marginRight + "px" : 0};
  text-align: left;
  padding: 1rem 1rem;
`

const MetricValue = styled.h1`
  color: ${props => props.metricValueColor ? props.metricValueColor : "#FFFFFF"};
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  font-family: Poppins;
  margin-left:${props => props.hasOwnProperty("icon") ? "10px" : "0px"};
`

const Metric = styled.h5`
  font-weight: normal;
  font-family: Poppins;
  text-transform: uppercase;
  color: ${props => props.metricNameColor ? props.metricNameColor : "#FFFFFF"};;
  font-style: normal;
  font-size: 13px;
  line-height: 123.3%;
`

const ProgressbarWrapper = styled.div`
 height: 50px;
 width: 50px;
 margin-left: auto;
 order: 2;
 margin-top: auto;
`


const SocialCard = (props) => {

  const {
    marginTop, marginBottom,
    marginLeft, marginRight, metricValue,
    metricName, children, backgroundColor,
    icon, metricValueColor, metricNameColor, percentage, trailColor,
  } = props

  return (
    <>
      <Container marginTop={marginTop} marginBottom={marginBottom} marginLeft={marginLeft} marginRight={marginRight}
                 backgroundColor={backgroundColor}>
        <Pane display="flex" flex={1} flexDirection="column" alignItems="flex-start" style={{ overflow: "hidden" }}>
          <Pane display="flex" flex={1} flexDirection="row" style={{ width: "100%" }}>
            {
              icon ? <img src={icon} height={40} width={40} style={{ marginTop: "12px" }}/> : null
            }

            {
              metricValue || metricValue === 0
                ?
                <MetricValue metricValueColor={metricValueColor} icon>
                  {metricValue}
                </MetricValue> : null
            }
            {percentage>0?
            <ProgressbarWrapper>
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles({
                  textColor: "#FFFFFF",
                  pathColor: "#FFFFFF",
                  trailColor: trailColor,
                })}
              /></ProgressbarWrapper>:null}
          </Pane>
          {
            metricName
              ?
              <Metric metricNameColor={metricNameColor}>
                {metricName}
              </Metric> : null
          }
          {children}
        </Pane>
      </Container>
    </>
  )
}

SocialCard.propTypes = {
  marginTop: PropTypes.any,
  marginBottom: PropTypes.any,
  marginLeft: PropTypes.any,
  marginRight: PropTypes.any,
  metricValue: PropTypes.any,
  metricName: PropTypes.string,
  children: PropTypes.any,
}

export default SocialCard
