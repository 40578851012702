import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Container = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  background: transparent;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-top: ${props => props.marginTop ? props.marginTop + "px" : "10px"};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom + "px" : 0};
  margin-left: ${props => props.marginLeft ? props.marginLeft + "px" : 0};
  margin-right: ${props => props.marginRight ? props.marginRight + "px" : 0};
  text-align: left;
  padding: 1rem 1rem;
`


const MetricValue = styled.h1`
  color: #362165;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 123.3%;
  font-family: Poppins;
`

const Metric = styled.h5`
  font-weight: normal;
  font-family: Poppins;
  text-transform: uppercase;
  color:#232ED1;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0.2em;
  line-height: 123.3%;
`

const ImageWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 50%;
  display:flex;
  justify-content:center;
  align-items: center;
  width: 50px;
  height: 50px;
`

const LeadCard = (props) => {

  const { marginTop, marginBottom, marginLeft, marginRight, metricValue, metricName, children, icon } = props

  return (
    <>
      <Container marginTop={marginTop} marginBottom={marginBottom} marginLeft={marginLeft} marginRight={marginRight}>
        <Row style={{marginLeft:"0px"}}>
          <Col sm={3} xs={3}  style={{margin:"auto"}}>
            {icon && <ImageWrapper><img style={{ marginTop: "35%" }} src={icon} height={25} width={25}/></ImageWrapper>}
          </Col>
          <Col sm={9} xs={9} >
            <Row style={{marginLeft:"0px"}}>
              <Col sm={12}>
              {
                metricValue || metricValue === 0
                  ?
                  <MetricValue>
                    {metricValue}
                  </MetricValue> : null
              }
              </Col>
            </Row>
            <Row style={{marginLeft:"0px",marginTop:"5%"}}>
              <Col sm={12}>
              {
                metricName
                  ?
                  <Metric>
                    {metricName}
                  </Metric> : null
              }
              {children}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

LeadCard.propTypes = {
  marginTop: PropTypes.any,
  marginBottom: PropTypes.any,
  marginLeft: PropTypes.any,
  marginRight: PropTypes.any,
  metricValue: PropTypes.any,
  metricName: PropTypes.string,
  children: PropTypes.any,
}

export default LeadCard
