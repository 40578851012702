import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Pane } from "evergreen-ui"

const Container = styled.div`
  display: flex;
  flex: 1;
  height: auto;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: ${props => props.marginTop ? props.marginTop + "px" : "10px"};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom + "px" : 0};
  margin-left: ${props => props.marginLeft ? props.marginLeft + "px" : 0};
  margin-right: ${props => props.marginRight ? props.marginRight + "px" : 0};
  text-align: left;
  padding: 1rem 1rem;
`

const MetricValue = styled.h1`
  color: #F06878;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 123.3%;
`

const Metric = styled.h5`
  font-weight: normal;
  text-transform: uppercase;
  color:#232ED1;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.2em;
  line-height: 123.3%;
  margin-top:5%;
`


const AnalyticsCard = (props) => {

  const { marginTop, marginBottom, marginLeft, marginRight, metricValue, metricName, children, icon } = props

  return (
    <>
      <Container marginTop={marginTop} marginBottom={marginBottom} marginLeft={marginLeft} marginRight={marginRight}>
        <Pane display="flex" flex={1} flexDirection="column" alignItems="flex-start" style={{ overflow: "hidden" }}>
          {
            metricValue || metricValue === 0
              ?
              <MetricValue>
                {metricValue}
              </MetricValue> : null
          }
          {
            metricName
              ?
              <Metric>
                {metricName}
              </Metric> : null
          }
          {children}
        </Pane>
        {icon && <img style={{ float: "right" }} src={icon} height={50} width={50}/>}
      </Container>
    </>
  )
}

AnalyticsCard.propTypes = {
  marginTop: PropTypes.any,
  marginBottom: PropTypes.any,
  marginLeft: PropTypes.any,
  marginRight: PropTypes.any,
  metricValue: PropTypes.any,
  metricName: PropTypes.string,
  children: PropTypes.any,
}

export default AnalyticsCard
