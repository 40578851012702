import React from 'react'
import { compose } from 'recompose'
import SEO from '../components/seo'
import Analytics from '../components/Analytics'
import { AuthUserContext, withAuthorization } from '../components/Session'

const AnalyticsPage = () => {
	
	return (
		<>
			<SEO title="Analytics" />
			<AuthUserContext.Consumer>
				{authUser => (
					<Analytics authUser={authUser} />
				)}
			</AuthUserContext.Consumer>
		</>
	)
}

const condition = authUser => !!authUser

export default compose(
	withAuthorization(condition),
)(AnalyticsPage)