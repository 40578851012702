import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "../../assets/css/most-viewed-photo.css"

const viewsIcon = require("../../assets/images/analytics/ico_view.svg")


const ViewCounts = styled.div`
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding-left:10px;
  width: 86px;
  height: 26px;
  margin-bottom: 10px;

  
`

const ImageWrapper = styled.div`
  height: 26px;
  padding-top:2px;
`


const CountWrapper = styled.div`
  margin-left: 10px;
`

const Count = styled.h5`
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 123.3%;
color: #362165;
`

const MostViewedPhoto = ({ photoURL, counts }) => {
  return (
    <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12 nopadding">
      <figure>
        <img src={photoURL} alt=""   />
        <figcaption>
          <ViewCounts>
            <ImageWrapper>
              <img src={viewsIcon} width={20} height={20}/>
            </ImageWrapper>
            <CountWrapper>
              <Count>{counts}</Count>
            </CountWrapper>
          </ViewCounts>
        </figcaption>
      </figure>
    </div>
  )
}

MostViewedPhoto.propTypes = {
  photoURL: PropTypes.any,
  counts: PropTypes.any,
}


export default MostViewedPhoto
