import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Pane, Combobox } from "evergreen-ui"
import moment from "moment"
import ProfileHeader from "./primitives/ProfileHeader"
import Footer from "./primitives/Footer"
import { Container } from "./primitives/Container"
import AnalyticsCard from "./primitives/AnalyticsCard"
import { ActionButton } from "./primitives/ActionButton"
import { withFirebase } from "./Firebase"
import _ from "lodash"
import { Doughnut, Line } from "react-chartjs-2"
import { Parser } from "json2csv"
import LeadCard from "./primitives/LeadCard"
import SocialCard from "./primitives/SocialCard"
import { Row, Col } from "react-bootstrap"
import MostViewedPhoto from "./primitives/MostViewedPhoto"
import DateRangePicker from "react-bootstrap-daterangepicker"
import {GoCalendar} from "react-icons/go";


const avatar = require("../assets/images/avatar.gif")
const fb = require("../assets/images/analytics/ico_fb.svg")
const twitter = require("../assets/images/analytics/ico_tw.svg")
const insta = require("../assets/images/analytics/ico_inst.svg")
const emailIcon = require("../assets/images/analytics/email.svg")
const userIcon = require("../assets/images/analytics/user.svg")
const homeIcon = require("../assets/images/analytics/home.svg")
const cellphoneIcon = require("../assets/images/analytics/cellphone.svg")
const cakeIcon = require("../assets/images/analytics/cake.svg")
const participantIcon = require("../assets/images/analytics/ico_participant.svg")
const photosIcon = require("../assets/images/analytics/ico_photos.svg")
const viewsIcon = require("../assets/images/analytics/ico_views.svg")
const leadsIcon = require("../assets/images/analytics/icon_leads.svg")


const Wrapper = styled.div`
 
`


const DownloadCsvWrapper = styled(Col)`
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items: flex-end;
`

const PageTitle = styled.h2`
  margin-bottom: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 123.3%;
  color: #362165;
`

const SectionTitle = styled.h3`
	margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 25px !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 123.3%;
  color: #362165;
`

const Overview = styled.div`
	/* display: flex; */
`

const SubHeaderPane = styled.div`
	display: flex;
  flex-direction: column;
	width: 100%;

	/* Medium devices (tablets, 768px and up) */
  @media (min-width: 1400px) { 
		flex-direction: row;
	}
`

const FilterOptions = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;

	/* .📦w_240px {
		max-width: 195px;
	}	 */

	input {
		max-width: 195px;
		height: 40px;
		border: 1px solid #DEDEDE;
		padding: 11px 1rem;
		border-radius: 4px;
		box-shadow: none;
		font-weight: normal;
		font-size: 16px;
		margin: 5px 0;
		color: #362165;

		div {
			max-width: 195px !important;
		}
	}
	
	input + button {
		height: 40px;
		margin: 5px 0;
		margin-left: -32px;
	}


	/* align-self: flex-start; */

	p {
		display: inline-block;
		margin-bottom: 0;
		margin-right: 10px;
	}

  @media (min-width: 1200px) { 
		flex-direction: row;
		justify-content: flex-end;
		
		input {
			width: 240px !important;
		}

		p {
			min-width: auto;
			display: inline-block;
		}
	}
`

const AllLeads = styled.div`
	display: flex;
`

const DateInputSpan = styled.span`
color:#1675e0;
font-size:14px
`

const DateDiv = styled.div`
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 5px 10px;
  width: 100%;
  border-radius:6px;
  border-color: rgb(222, 222, 222);

`


const datePickerSettings = {
  startDate: moment().startOf("month").toDate(),
  endDate: moment().endOf("day").toDate(),
  locale: {
    format: "ddd,MMM DD,YYYY",
  },
  ranges: {
    "Last 7 Days": [
      moment().subtract(6, "days").startOf("day").toDate(),
      moment().endOf("day").toDate(),
    ],
    "This Month": [
      moment().startOf("month").toDate(),
      moment().endOf("day").toDate(),
    ],
    "Year to Date": [
      moment().startOf("year").toDate(),
      moment().endOf("day").toDate(),
    ],
    Today: [
      moment().startOf("day").toDate(),
      moment().endOf("day").toDate(),
    ],
    Yesterday: [
      moment().subtract(1, "days").startOf("day").toDate(),
      moment().subtract(1, "days").endOf("day").toDate(),
    ],
  },
}

const datasetOptions = {
  label: "sessions",
  fill: false,
  backgroundColor: "rgba(155,163,227,.5)",
  borderColor: "#2234c9",
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "#2234c9",
  pointBackgroundColor: "#fff",
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: "#F06878",
  pointHoverBorderColor: "#F06878",
  pointHoverBorderWidth: 2,
  pointRadius: 5,
  pointHitRadius: 5,
}

const defaultGallery = {
  id: "",
  data: {
    accountID: "",
    galleryID: "",
    hashTag: "",
    name: "All Photos",
    private: true,
    unlisted: true,
  },
}

const Analytics = (props) => {

  const { authUser, firebase } = props
  const [accountInfo, setAccountInfo] = useState(null)
  const [selectedDate, setSelectedDate] = useState([datePickerSettings.startDate, datePickerSettings.endDate])
  const [selectedGallery, setSelectedGallery] = useState(defaultGallery.data)
  const [galleries, setGalleries] = useState([defaultGallery])
  const [participants, setParticipants] = useState([])
  const [photos, setPhotos] = useState([])
  const [chartData, setChartData] = useState({})
  const [leads, setLeads] = useState({})
  const [totalViews, setTotalViews] = useState(0)
  const [totalDownloads, setTotalDownloads] = useState(0)
  const [totalShare, setTotalShare] = useState([0, 0, 0])
  const [deviceSessionData, setDeviceSessionData] = useState({})
  const [mostViewedPhotos, setMostViewedPhotos] = useState([])
  const [socialPercentage, setSocialPercentage] = useState([0, 0, 0])
  const [averageSessionTime, setAverageSessionTime] = useState(0)


  let doughnutData = {
    labels: ["iOS", "Android", "Desktop"],
    datasets: [{
      data: [0, 0, 0],
      backgroundColor: ["#89D2DC", "#6564DB", "#F06878"],
      hoverBackgroundColor: ["#89D2DC", "#6564DB", "#F06878"],
    }],
  }


  useEffect(() => {
    calculateLeads()
    setChartData({})
    if (participants.length > 0) {

      let data = []

      participants.forEach((p) => {
        let currentData = data.filter(d => {
          return d.date === moment(p.data.localDate, "YYYY-MM-DD hh:mm a").format("D MMMM")
        })[0]

        if (currentData) {
          currentData.value += 1
          data.splice(_.findIndex(data, { date: moment(p.data.localDate, "YYYY-MM-DD hh:mm a").format("D MMMM") }), 1, currentData)
        } else {
          currentData = {
            date: moment(p.data.localDate, "YYYY-MM-DD hh:mm a").format("D MMMM"),
            value: 1,
          }
          data = data.concat(currentData)
        }
      })

      let dataForChart = {
        labels: data.map(d => d.date),
        datasets: [
          {
            ...datasetOptions,
            data: data.map(d => d.value),
          },
        ],
      }


      setChartData(dataForChart)
    }
  }, [participants])

  useEffect(() => {
    if (firebase) {
      getStats(null, selectedDate)

      firebase.db.collection("accounts")
        .doc(authUser.uid)
        .get()
        .then(doc => {
          const data = doc.data()
          setAccountInfo(data)

          // Create a reference to collection
          const galleriesRef = firebase.db.collection("galleries")

          // Create a query against the collection.
          const galleriesQuery = galleriesRef.where("accountID", "==", authUser.uid)
          // const galleriesQuery = galleriesRef.where('accountID', '==', '3MERATwFORajfFnADzUmqVjV88Z2')

          galleriesQuery
            .get()
            .then((querySnapshot) => {
              let galleriesData = []
              querySnapshot.forEach((doc) => {
                galleriesData = galleriesData.concat({
                  id: doc.id,
                  data: doc.data(),
                })
              })

              setGalleries([defaultGallery].concat(galleriesData))
            })
            .catch((error) => {
              console.log("Error getting documents: ", error)
            })

        })
    }
  }, [firebase])

  const getStats = async (gallery, dateRange) => {
    gallery ? setSelectedGallery(gallery) : null  //TODO: alert no gallery selected
    dateRange ? setSelectedDate(dateRange) : null //TODO: alert no dateRange selected

    let photos = []
    let analytics = []
    let galleriesSnapshot
    let analyticsSnapshot

    if (galleries.id) {
      // Create a reference to collection
      galleriesSnapshot = await firebase.db
        .collection("photos")
        .where("galleryID", "==", gallery.galleryID.trim())
        .get()
        .catch((error) => {
          console.log("Error getting documents: ", error)
        })
    } else {
      // Create a reference to collection
      galleriesSnapshot = await firebase.db
        .collection("photos")
        .where("accountID", "==", authUser.uid)
        .get()
        .catch((error) => {
          console.log("Error getting documents: ", error)
        })
    }

    analyticsSnapshot = await firebase.db
      .collection("analytics")
      .where("accountID", "==", authUser.uid)
      .get()
      .catch((error) => {
        console.log("Error getting documents: ", error)
      })

    analyticsSnapshot.docs.map(doc => {
      analytics = analytics.concat({
        id: doc.id,
        data: {
          ...doc.data(),
        },
      })
    })

    galleriesSnapshot.docs.map(doc => {
      photos = photos.concat({
        id: doc.id,
        data: {
          ...doc.data(),
        },
      })
    })

    analytics = analytics.filter(a => {
      const startDate = moment(dateRange[0]).startOf("day").toDate()
      const check = moment(a.data.localDate, "YYYY-MM-DD hh:mm a").toDate()
      const endDate = moment(dateRange[1]).endOf("day").toDate()

      return check > startDate && check < endDate
    })

    photos = photos.filter(p => {
      const startDate = moment(dateRange[0]).startOf("day").toDate()
      const check = p.data.createDate.toDate()
      const endDate = moment(dateRange[1]).endOf("day").toDate()

      return check > startDate && check < endDate
    })

    //filter out duplicate participants
    analytics = _.uniqBy(analytics, (a) => {
      return a.data.phoneNumber
    })


    const photoViewCounterRef = []
    const downloadCounterRef = []
    const fbCounterRef = []
    const instaCounterRef = []
    const twitterCounterRef = []
    photos.forEach(async data => {
      photoViewCounterRef.push(firebase.db.collection(`photos/${data.id}/views`).get())
      downloadCounterRef.push(firebase.db.collection(`photos/${data.id}/downloads`).get())
      fbCounterRef.push(firebase.db.collection(`photos/${data.id}/facebookShare`).get())
      instaCounterRef.push(firebase.db.collection(`photos/${data.id}/instagramShare`).get())
      twitterCounterRef.push(firebase.db.collection(`photos/${data.id}/twitterShare`).get())
    })

    const viewsCountArray = await Promise.all(photoViewCounterRef)
    const totalViewsCount = calculateCounts(viewsCountArray, dateRange)
    const devices = calculateDevices(viewsCountArray, dateRange)

    const downloadCounter = await Promise.all(downloadCounterRef)
    const totalDownloads = calculateCounts(downloadCounter, dateRange)

    const facebookShareCounter = await Promise.all(fbCounterRef)
    const fbCounter = calculateCounts(facebookShareCounter, dateRange)

    const instaCounterArrayRef = await Promise.all(instaCounterRef)
    const instaCounter = calculateCounts(instaCounterArrayRef, dateRange)

    const twitterCounterArrayRef = await Promise.all(twitterCounterRef)
    const twitterCounter = calculateCounts(twitterCounterArrayRef, dateRange)


    // Update line data
    doughnutData.datasets[0].data = [devices.hasOwnProperty("iOS") ? devices["iOS"] : 0,
      devices.hasOwnProperty("Android") ? devices["Android"] : 0,
      devices.hasOwnProperty("Desktop") ? devices["Desktop"] : 0]


    // find most viewed photos
    const mostViewedPhotos = getMostViewsPhotos(mostViewedIndex(viewsCountArray, dateRange), photos)
    const averageTime = getAverageSessionTime(analytics)
    console.log(analytics);
    setMostViewedPhotos(mostViewedPhotos)
    setPhotos(photos)
    setParticipants(analytics)
    setTotalViews(totalViewsCount)
    setTotalDownloads(totalDownloads)
    setTotalShare([fbCounter, instaCounter, twitterCounter])
    setDeviceSessionData(doughnutData)
    setSocialPercentage(getPercentage([fbCounter, instaCounter, twitterCounter]))
    setAverageSessionTime(averageTime)

  }

  const getPercentage = (totalShareData) => {
    const total = totalShareData.reduce((accumulator, currentValue) => accumulator + currentValue)
    return totalShareData.map(data => {
      if (total > 0) {
        return ((data / total) * 100).toFixed(1)
      } else {
        return 0
      }

    })
  }

  const getAverageSessionTime = (analyticsData) => {
    const totalTime = analyticsData.reduce((accumulator, customElements) => accumulator + customElements.data.sessionLength, 0)
    console.log("totalTime", totalTime)
    if (totalTime > 0) {
      return (totalTime / analyticsData.length).toFixed(2) + " Sec"
    } else {
      return 0 + " Sec"
    }

  }


  const calculateCounts = (viewsCountArray, dateRange) => {
    return viewsCountArray.map(data => {
      return data.docs.filter(doc => {
        const startDate = moment(dateRange[0]).startOf("day").toDate()
        const check = doc.data().date.toDate()
        const endDate = moment(dateRange[1]).endOf("day").toDate()
        return check > startDate && check < endDate
      }).length
    }).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  }

  const mostViewedIndex = (viewsCountArray, dateRange) => {
    const counts = viewsCountArray.map(data => {
      return data.docs.filter(doc => {
        const startDate = moment(dateRange[0]).startOf("day").toDate()
        const check = doc.data().date.toDate()
        const endDate = moment(dateRange[1]).endOf("day").toDate()
        return check > startDate && check < endDate
      }).length
    })
    let countsWithIndex = counts.map((e, i) => {
      return { ind: i, val: e }
    })
    const countsData = countsWithIndex.sort((x, y) => {
      return y.val - x.val
    })
    return countsData

  }

  const getMostViewsPhotos = (mostViewed, photos) => {
    const maxLength = mostViewed.length > 6 ? 6 : mostViewed.length
    const mostViewedPhotoList = []
    for (let i = 0; i < maxLength; i++) {
      mostViewedPhotoList.push({ photoURL: photos[mostViewed[i].ind].data.photoURL, counts: mostViewed[i].val })
    }
    return mostViewedPhotoList
  }

  const calculateDevices = (viewsCountArray, dateRange) => {
    return viewsCountArray.map(data => {
      return data.docs.filter(doc => {
        const startDate = moment(dateRange[0]).startOf("day").toDate()
        const check = doc.data().date.toDate()
        const endDate = moment(dateRange[1]).endOf("day").toDate()
        return check > startDate && check < endDate
      })
    }).reduce((arr, data) => {
      return arr.concat(data.map(d => d.data()))
    }, []).reduce((map, obj) => {
      if (map.hasOwnProperty(obj.deviceType)) {
        map[obj.deviceType] = map[obj.deviceType] + 1
      } else {
        map[obj.deviceType] = 1
      }
      return map
    }, {})
  }

  const calculateLeads = () => {

    //Not sure, but I think these data points are dynamic and should not be hardcoded (may need to refactor later)
    const emailAddresses = participants.filter(a => a.data.emailAddress && a.data.emailAddress.length > 0).map(a => a.data.emailAddress).length
    const phoneNumbers = participants.filter(a => a.data.phoneNumber && a.data.phoneNumber.length > 0).map(a => a.data.phoneNumber).length
    const firstName = participants.filter(a => a.data.firstName && a.data.firstName.length > 0).map(a => a.data.firstName).length
    const lastName = participants.filter(a => a.data.lastName && a.data.lastName.length > 0).map(a => a.data.lastName).length
    const zipCode = participants.filter(a => a.data.zipCode && a.data.zipCode.length > 0).map(a => a.data.zipCode).length
    const birthDate = participants.filter(a => a.data.birthDate && a.data.birthDate.length > 0).map(a => a.data.birthDate).length
    const totalLeads = emailAddresses + phoneNumbers + firstName + lastName + zipCode + birthDate

    setLeads({
      emailAddresses: emailAddresses,
      phoneNumbers: phoneNumbers,
      firstNames: firstName,
      lastNames: lastName,
      zipCodes: zipCode,
      birthDates: birthDate,
      totalLeads: totalLeads,
    })
  }

  const capitalize = (s) => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const downloadCSV = () => {
    if (participants.length > 0) {
      let fileName = selectedGallery ? selectedGallery.name : "All_Photos"
      const json2csvParser = new Parser()
      const csv = json2csvParser.parse(participants.map(p => {
        let updatedDate = {}
        const data = p.data
        for (let key in data) {
          updatedDate[capitalize(key)] = data[key]
        }
        return updatedDate
      }))

      const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csv)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", `${fileName}.csv`)
      document.body.appendChild(link) // Required for FF

      link.click()
    } else {
      alert("There is no data to download")
    }

  }

  return (
    <>
      {
        accountInfo ?
          <Wrapper>
            <ProfileHeader accountName={`${accountInfo.firstName}`}
                           userImage={accountInfo.profileImageUrl ? accountInfo.profileImageUrl : avatar}/>
            <Container marginTop={16} marginBottom={16} backgroundColor="white" color="#362165">
              <SubHeaderPane>
                <Pane alignSelf="center">
                  <PageTitle>Analytics</PageTitle>
                </Pane>
                <FilterOptions>
                  <Pane display="flex" alignItems="center" marginLeft={15}>
                    <p>Galleries: </p>
                    <Combobox
                      openOnFocus
                      items={galleries.map((g) => g.data)}
                      itemToString={item => item ? item.name : ""}
                      onChange={selected => getStats(selected, selectedDate)}
                      initialSelectedItem={{ name: "All Photos" }}
                      placeholder="select a gallery"
                    />
                  </Pane>
                  <Pane display="flex" alignItems="center" marginLeft={15}>
                    <p>Date: </p>
                    <DateRangePicker initialSettings={datePickerSettings} onApply={(event, picker) => {
                      getStats(selectedGallery, [picker.startDate.toDate(), picker.endDate.toDate()])
                    }}>
                      <DateDiv>
                        <DateInputSpan>{moment(selectedDate[0]).format("ddd,MMM DD,YYYY")}~{moment(selectedDate[1]).format("ddd,MMM DD,YYYY")}</DateInputSpan>
                        &nbsp;
                        <GoCalendar style={{color:'grey'}}  />
                      </DateDiv>
                    </DateRangePicker>
                  </Pane>
                </FilterOptions>
              </SubHeaderPane>
            </Container>
            <Container backgroundColor="#F7F7F9" color="#362165" flexDirection="column">
              <SectionTitle>Overview</SectionTitle>
              <Overview>
                <div className="row">
                  <div className="col-md-4">
                    <AnalyticsCard metricValue={participants.length} metricName="Total Participants"
                                   icon={participantIcon}/>
                  </div>
                  <div className="col-md-4">
                    <AnalyticsCard metricValue={photos.length} metricName="Total Photos" icon={photosIcon}/>
                  </div>
                  <div className="col-md-4">
                    <AnalyticsCard metricValue={totalViews} metricName="Total Views" icon={viewsIcon}/>
                  </div>
                </div>
              </Overview>

              <SectionTitle>Sessions
                {
                  averageSessionTime !== "0 Sec" ?
                    <span style={{
                      float: "right",
                      fontSize: "15px",
                    }}> Average Session Time: {averageSessionTime}</span> : null
                }
              </SectionTitle>
              <Pane display="flex">
                <AnalyticsCard>
                  <Line height={80}
                        data={chartData}
                        options={{ responsive: true }}
                  />
                </AnalyticsCard>
              </Pane>


              <Row>
                <Col>
                  <SectionTitle>Data Capture Leads</SectionTitle>
                  <AllLeads>
                    <div className="row">
                      <Col sm={6} xs={12}>
                        <AnalyticsCard icon={leadsIcon} metricValue={leads.totalLeads} metricName="Total Leads"/>
                      </Col>
                      <DownloadCsvWrapper sm={6} xs={12}>
                        <ActionButton backgroundColor="#6564DB" color="#6564DB" marginTop={15} marginLeft={15} border
                                      style={{ borderWidth: "2px", borderStyle: "solid", fontSize: "16px" }}
                                      onClick={downloadCSV}>
                          Download CSV
                        </ActionButton>
                      </DownloadCsvWrapper>
                      <Col sm={6} xs={12}>
                        <LeadCard icon={emailIcon} metricValue={leads.emailAddresses} metricName="Email Addresses"/>
                      </Col>
                      <Col sm={6} xs={12}>
                        <LeadCard icon={cellphoneIcon} metricValue={leads.phoneNumbers} metricName="Phone Numbers"/>
                      </Col>
                      <Col sm={6} xs={12}>
                        <LeadCard icon={userIcon} metricValue={leads.firstNames} metricName="First Names"/>
                      </Col>
                      <Col sm={6} xs={12}>
                        <LeadCard icon={userIcon} metricValue={leads.lastNames} metricName="Last Names"/>
                      </Col>
                      <Col sm={6} xs={12}>
                        <LeadCard icon={homeIcon} metricValue={leads.zipCodes} metricName="Zip Codes"/>
                      </Col>
                      <Col sm={6} xs={12}>
                        <LeadCard icon={cakeIcon} metricValue={leads.birthDates} metricName="Birth Dates"/>
                      </Col>
                    </div>
                  </AllLeads>
                </Col>
                <Col>
                  <SectionTitle style={{ marginLeft: "15%" }}>Devices</SectionTitle>
                  <Pane display="flex" alignItems="flex-end">
                    <Doughnut data={deviceSessionData}/>
                  </Pane>
                </Col>
              </Row>
              <br/>


              <SectionTitle>Social</SectionTitle>
              <Overview>
                <div className="row">
                  <div className="col-md-3">
                    <SocialCard icon={fb} metricValue={totalDownloads} metricName="DOWNLOAD BUTTON CLICKS"
                                metricValueColor={"#89d2dc"} metricNameColor={"#3e48d7"}/>
                  </div>
                  <div className="col-md-3">
                    <SocialCard icon={fb} backgroundColor={"#4267b2"} metricValue={totalShare[0]}
                                metricName="FACEBOOK SHARE BUTTON CLICKS" percentage={socialPercentage[0]}
                                trailColor={"#6386CE"}/>
                  </div>
                  <div className="col-md-3">
                    <SocialCard icon={insta} backgroundColor={"#dd2a7b"} metricValue={totalShare[1]}
                                metricName="INSTAGRAM SHARE BUTTON CLICKS" percentage={socialPercentage[1]}
                                trailColor={"#F859A1"}/>
                  </div>
                  <div className="col-md-3">
                    <SocialCard icon={twitter} backgroundColor={"#00acee"} metricValue={totalShare[2]}
                                metricName="TWITTER SHARE BUTTON CLICKS" percentage={socialPercentage[2]}
                                trailColor={"#54C5F0"}/>
                  </div>
                </div>
              </Overview>

              <SectionTitle>Most Viewed</SectionTitle>
              <Overview>
                <div className="row justify-content-md-center" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                  {mostViewedPhotos.map((data, index) => {
                    return <MostViewedPhoto photoURL={data.photoURL} counts={data.counts} key={index}/>
                  })}
                </div>
              </Overview>
            </Container>
            <Footer/>
          </Wrapper> : null
      }
    </>
  )
}

Analytics.propTypes = {
  authUser: PropTypes.any,
  firebase: PropTypes.any,
}

export default withFirebase(Analytics)
